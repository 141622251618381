// store/slices/videoEditorSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    videoDataUrl: null,
    dimensions: { width: 568, height: 1000 }, // Pevné rozměry
    videoTaken: false,
    isCanvasReady: false,
    showSegmentation: false,
    isStreamReady: false,
    isRecording: false,
    stickers: [],
    maskDataUrl: null,
    isSegmentationLoading: false,
};

const videoEditorSlice = createSlice({
    name: 'videoEditor',
    initialState,
    reducers: {
        setDimensions: (state) => {
            state.dimensions = { width: 568, height: 1000 };
        },
        setVideoDataUrl: (state, action) => {
            state.videoDataUrl = action.payload;
        },
        setVideoTaken: (state, action) => {
            state.videoTaken = action.payload;
        },
        resetVideoEditor: (state) => {
            state.videoDataUrl = null;
            state.videoTaken = false;
            state.isCanvasReady = false;
            state.showSegmentation = false;
            state.isStreamReady = false;
            state.isRecording = false;
            state.stickers = [];
            state.maskDataUrl = null;
        },
        setIsCanvasReady: (state, action) => {
            state.isCanvasReady = action.payload;
        },
        setShowSegmentation: (state, action) => {
            state.showSegmentation = action.payload;
        },
        setIsStreamReady: (state, action) => {
            state.isStreamReady = action.payload;
        },
        setIsRecording: (state, action) => {
            state.isRecording = action.payload;
        },
        setStickers: (state, action) => {
            state.stickers = action.payload;
        },
        setMaskDataUrl: (state, action) => {
            state.maskDataUrl = action.payload;
        },
        setIsSegmentationLoading(state, action) {
            state.isSegmentationLoading = action.payload;
        },
    },
});

export const {
    setDimensions,
    setVideoDataUrl,
    setVideoTaken,
    resetVideoEditor,
    setIsCanvasReady,
    setShowSegmentation,
    setIsStreamReady,
    setIsRecording,
    setStickers,
    setMaskDataUrl,
    setIsSegmentationLoading,
} = videoEditorSlice.actions;

export default videoEditorSlice.reducer;
