import { createSlice } from '@reduxjs/toolkit'

export const santaFigureSlice = createSlice({
    name: 'santaFigure',
    initialState: {
        b1: 0,
        b2: 0,
        b3: 0,
        b4: 0,
        b5: 0,
        b6: 0,
        b7: 0,
        b8: 0,
        b9: 0,
        visible: true
    },
    reducers: {
        hideSanta: state => { state.visible = false },
        setB1: (state, action) => { state.b1 = action.payload },
        setB2: (state, action) => { state.b2 = action.payload },
        setB3: (state, action) => { state.b3 = action.payload },
        setB4: (state, action) => { state.b4 = action.payload },
        setB5: (state, action) => { state.b5 = action.payload },
        setB6: (state, action) => { state.b6 = action.payload },
        setB7: (state, action) => { state.b7 = action.payload },
        setB8: (state, action) => { state.b8 = action.payload },
        setB9: (state, action) => { state.b9 = action.payload }
    }
})

export const { hideSanta, setB1, setB2, setB3, setB4, setB5, setB6, setB7, setB8, setB9 } = santaFigureSlice.actions
export const santaFigureReducer = santaFigureSlice.reducer
