import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isGiver: true,
        isWinner: true,
        isStarted: false
    },
    reducers: {
        setIsGiver: state => { state.isGiver = true },
        setIsReceiver: state => { state.isGiver = false },
        setIsWinner: state => { state.isWinner = true },
        setIsLoser: state => { state.isWinner = false },
        setIsStarted(state, action) {
            state.isStarted = action.payload;
        }
    }
})

export const { setIsGiver, setIsReceiver, setIsWinner, setIsLoser, setIsStarted } = userSlice.actions
export const userReducer = userSlice.reducer
