import { createSlice } from '@reduxjs/toolkit';

const walkthroughSlice = createSlice({
    name: 'walkthrough',
    initialState: {
        currentScreen: 'A',
        messageType: '',
        isBlurred: false,
        messageAudio: false,
        messageId: '',
        authToken: '',
        noFreeSantas: true,
    },
    reducers: {
        setScreen(state, action) {
            state.currentScreen = action.payload;
        },
        setMessageType(state, action) {
            state.messageType = action.payload;
        },
        setIsBlurred(state, action) {
            state.isBlurred = action.payload;
        },
        setMessageAudio(state, action) {
            state.messageAudio = action.payload;
        },
        setMessageId(state, action) {
            state.messageId = action.payload;
        },
        setAuthToken(state, action) {
            state.authToken = action.payload;
        },
        setNoFreeSantas(state, action) {
            state.noFreeSantas = action.payload;
        },
    },
});

export const { setScreen, setMessageType, setIsBlurred, setMessageAudio, setMessageId, setAuthToken, setNoFreeSantas } = walkthroughSlice.actions;
export default walkthroughSlice.reducer;