export default function Footer() {
    return (
        <footer className="footer"> 
            <div className="footer-socials">
                <a className="footer-socials-instagram" href="https://www.instagram.com/milka_deutschland/" target="_blank"></a>
                <a className="footer-socials-facebook" href="https://www.facebook.com/MilkaDACH/" target="_blank"></a>
                <a className="footer-socials-youtube" href="https://www.youtube.com/user/Milka" target="_blank"></a>
                <a className="footer-socials-pinterest" href="https://www.pinterest.de/milka_dach/" target="_blank"></a>
                <a className="footer-socials-tiktok" href="https://www.tiktok.com/@milka_de_at" target="_blank"></a>
            </div>
            <div className="footer-links">
                <div className="footer-links-group">
                    <a href="https://privacy.mondelezinternational.com/eu/de-de/terms-of-use/" target="_blank">Bedingungen zur Nutzung</a>
                    <a href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank">Datenschutzerklärung</a>
                    <a href="https://contactus.mdlzapps.com/milka/contact/de-DE/" target="_blank">Kontakt</a>
                    <a href="/pdf/Allgemeine_geschaftsbedingungen.pdf" target="_blank">Allgemeine Geschäftsbedingungen</a>
                    <a href="/cookies" target="_blank">Cookies</a>
                    {/* <a href="#" target="_blank">Impressum</a> */}
                    {/* <a href="#" target="_blank">Nutzungsbasierte Online-Werbung</a> */}
                </div>
                <div className="footer-links-group">
                    {/* <a href="#" target="_blank">Mondelez International</a> */}
                    {/* <a href="#" target="_blank">Cookie Richtlinie</a> */}
                    {/* <a href="#" target="_blank">FAQ</a> */}
                </div>
            </div>
            <div className="footer-divider bg-fresh"></div>
            <div className="footer-brand">
                <div className="footer-brand-logo">
                    <a href="https://milka.de/" target="_blank"><img src="/images/mondelez.png" alt="Mondelez"/></a>
                </div>
                <span className="footer-brand-text">© 2024 MONDELEZ DEUTSCHLAND SERVICES GMBH & CO. KG - ALL RIGHTS RESERVED</span>
            </div>
        </footer>
    );
}