import { createSlice } from '@reduxjs/toolkit'
import * as Constants from '../Constants'

export const cameraSlice = createSlice({
    name: 'camera',
    initialState: {
        mode: Constants.CameraModes.Rotating,
        target: Constants.CameraTargets.None,
        locked: true
    },
    reducers: {
        changeCameraModeToOrbit: state => { state.mode = Constants.CameraModes.Orbit },
        changeCameraTargetToSanta: state => { state.target = Constants.CameraTargets.Santa },
        changeCameraTargetToSide: state => { state.target = Constants.CameraTargets.Side },
        lockCamera: state => { state.locked = true },
        unlockCamera: state => { state.locked = false }
    }
})

export const { changeCameraModeToOrbit, changeCameraTargetToSanta, changeCameraTargetToSide, lockCamera, unlockCamera } = cameraSlice.actions
export const cameraReducer = cameraSlice.reducer
