import '../styles/tailwind.css'
import '../styles/global.scss';
import { appWithTranslation } from 'next-i18next';
import { Provider } from 'react-redux';
import store from '../store';
import Layout from '../components/Layout';
import React from 'react';

function MyApp({ Component, pageProps }) {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <Layout>
                    <Component {...pageProps} />
                </Layout>
            </Provider>
        </React.StrictMode>
    );
}

export default appWithTranslation(MyApp);