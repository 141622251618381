import Footer from './Footer';
import Header from './Header';

export default function Layout({ children }) {    
    return (
        <div className="milka">
            <Header />
            {children}
            <Footer />
        </div>
    );
}